<template>
    <div :class="$mobile ? 'nav-warp' : 'nav'">
        <div class="nav-item" :class="{active: $route.path === '/'}" @click="goto('/', 0)">
            <span>{{$t('menu.home')}}</span>
        </div>
        <div class="nav-item" @click="noRoute">
            <span>{{$t('menu.marktplace')}}</span>
        </div>
        <div class="nav-item" :class="{active: $route.path === '/tools'}" @click="goto('/tools', 2)">
            <span>{{$t('menu.myTools')}}</span>
        </div>
        <div class="nav-item" :class="{active: $route.path === '/bridge'}" @click="goto('/bridge', 3)">
            <span>{{$t('bridge.bridge')}}</span>
        </div>
        <slot name="footer">

        </slot>
    </div>
</template>

<script>
import logo from "../../assets/favicon.png";

export default {
    name: 'MenuPage',
    methods: {
        goto(path) {
            this.open = false
            this.$router.push(path)
        },
        noRoute() {
            let timeout = null
            this.open = false
            this.$msgbox({
                dangerouslyUseHTMLString: true,
                showClose: false,
                showCancelButton: false,
                showConfirmButton: false,
                customClass: 'custom-message-box',
                center: true,
                title: '',
                message: `
                    <div class="custom-message">
                        <div><img src="${logo}" /></div>
                        <span>Coming soon</span>
                    </div>
                `,
                beforeClose(action, instance, done) {
                    if (timeout) {
                        clearTimeout(timeout)
                        done()
                        return
                    }
                    done()
                }
            })
            timeout = setTimeout(() => {
                this.$msgbox.close()
            }, 3000)
        }
    }
}
</script>
